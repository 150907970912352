@use '../LandingPage.mixins.scss' as *;
@import '../../../styles/propertySets.css';
@import '../../../styles/customMediaQueries.css';

.sectionContainer {
  width: 100%;

  @include contentPadding;
  background-color: white;

    
  padding-top: 64px;
  padding-bottom: 64px;
  

  @media (--viewportSmall) {
    padding-top: 24px;
  padding-bottom: 24px;
  }
  @media (--viewportMedium) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

}

.sectionContent {
  @include flexColumn;
  height: 100%;
}

.allCategoriesContainer {
  width: 100%;
  display: flex;
  @include flexStart;
  padding-top: 0;
  padding-bottom: 16px;
  padding-top: 16px;

  h2 {
    /** marketplaceH1FontStyles*/  
    font-family: 'Greycliff';
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -1px;
/** end */
    color: white;
  }
}

.categoriesLinksContainer {
  width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 24px;
    align-items: center
}

@media (--viewportMedium) {

  .allCategoriesContainer {
    padding-top: 0px;
  }
}
.textWrapper{
  
  max-width: calc(100vw - 20px);
    height: auto;
 
    @media (--viewportMedium) {
      max-width: 40vw;
    }
}
.categoryCardsContainer {
  width: max-content;
 
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-x: auto; */
  padding: 0;
  margin-bottom: 32px;
  text-decoration: none;

  padding: 0;
  margin-bottom: 32px;
  text-decoration: none;
}

.categoryCard {
  display: flex;
  flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;  


  .imgWrapper {
    overflow: hidden;
   
    max-width: calc(100vw - 20px);
    height: auto;
 
    @media (--viewportMedium) {
      max-width: 40vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.linkWrapper {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
}
