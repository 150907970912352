@use '../LandingPage.mixins.scss' as *;
@import '../../../styles/propertySets.css';
@import '../../../styles/customMediaQueries.css';

.sectionContainer {
  width: 100%;

  @include contentPadding;
  background-color: black;

    
  padding-top: 64px;
  padding-bottom: 64px;
  

  @media (--viewportSmall) {
    padding-top: 24px;
  padding-bottom: 24px;
  }
  @media (--viewportMedium) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

}

.sectionContent {
  @include flexColumn;
  height: 100%;
}

.allCategoriesContainer {
  width: 100%;
  display: flex;
  @include flexStart;
  padding-top: 0;
  padding-bottom: 16px;
  padding-top: 16px;

  h2 {
    /** marketplaceH1FontStyles*/  
    font-family: 'Greycliff';
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -1px;
/** end */
    color: white;
  }
}

.categoriesLinksContainer {
  width: 100%;
  @include flexRow;

  padding-top: 16px;
}

@media (--viewportMedium) {
  .categoriesLinksContainer {
    padding-top: 16px;
  }
  .allCategoriesContainer {
    padding-top: 0px;
  }
}

.categoryCardsContainer {
  width: max-content;
  @include flexRow;


  overflow-x: auto;
  padding-top: 16px;
  padding: 0;
  margin-bottom: 32px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  h3 {
    &:hover{
      text-decoration: none;
    }
  }
}

.categoryCard {
  @include flexColumn;
  @include flexCenter;
  flex: 1;
  background: white;
  border-radius: 16px;
  border: 1px solid rgba(214, 197, 254, 0.6);
  margin: 0px 24px 0px 0px;
  padding: 18px 18px 6px 18px;
  min-width: 314px;
  &:hover{
    text-decoration: none;
  }

  @media (--viewportLarge) {
    padding: 24px 24px 8px 24px;
  }

  .imgWrapper {
    overflow: hidden;
    border-radius: 16px;
    width: 100%;

    height: 265px;

    @media (--viewportLarge) {
     
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .label {
    height: 64px;
    @include flexColumn;
    @include flexCenter;

    /** marketplaceH3FontStyles*/  
font-family: 'Greycliff';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
/** end */
    color: var(--fave-color-black-s900);
    &:hover{
      text-decoration: none;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
     cursor: pointer;
     text-decoration: none;
    }
  }
}

.linkWrapper {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
}
