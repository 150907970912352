@use '../LandingPage.mixins.scss' as *;
@import '../../../styles/propertySets.css';
@import '../../../styles/customMediaQueries.css';

.sectionContainer {
  @include flexColumn;
  @include flexCenter;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 64px;
  background: linear-gradient(359.99deg, #f9f8ff 0.01%, #ffffff 99.98%);
  overflow: hidden;

  @media (--viewportSmall) {
    padding-top: 24px;
  padding-bottom: 24px;
  }
  @media (--viewportMedium) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.sectionContent {
  @include flexColumn;
  @include flexCenter;
  @include contentPadding;
  width: 100%;
  height: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.appInfoContainer {
  flex: 0 1 50%;
  @include flexRow;
  @include flexCenter;
  width: 100%;
  height: 100%;

  .appInfoWrapper {
    display: flex;
    flex-direction: column;
    @include flexCenter;
    padding: 16px;
    width: 100%;
    background: linear-gradient(83.64deg, #101010 10.03%, #343434 89.97%);

    border-radius: 16px;
    margin-top: 16px;

    @media (--viewportSmall) {
      max-width: 320px;
      padding: 20px;
    }

    @media (--viewportMedium) {
      padding: 24px;
    }

    @media (--viewportLarge) {
      flex-direction: row;
      padding: 20px 60px;
      max-width: 608px;
    }

    .qrCodeContainer {
      @include flexColumn;
      @include flexCenter;
      width: 112px;
      padding: 8px;
      margin-bottom: 16px;
      background-color: var(--fave-color-white);
      border-radius: 8px;

      img {
        width: 100%;
      }

      .label {
        /**fave-copy-xsmall-regular*/  
font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 200;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */
        padding: 2px;
      }
    }

    .infoBox {
      @include flexColumn;
      width: 100%;

      @media (--viewportLarge) {
        padding: 20px;
      }

      .infoCaption {
        /** marketplaceH3FontStyles*/  
font-family: 'Greycliff';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
/** end */
        font-style: --fave-font-default;
        color: var(--fave-color-white);
        margin: 0px;

        @media (--viewportMedium) {
          /** marketplaceH1FontStyles*/  
    font-family: 'Greycliff';
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -1px;
/** end */
          font-style: --fave-font-default;
        }

        @media (--viewportLarge) {
          /** marketplaceH1FontStyles*/  
 font-family: 'Greycliff';
    font-size: 40px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -0.01em;
/** end */
          font-style: --fave-font-default;
        }
      }

      .infoText {
        /** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
        color: var(--fave-color-white);
        margin: 24px 0px;

        @media (--viewportMedium) {
          /**fave-copy-large-regular*/  
  font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
/** end */
          font-style: --fave-font-default;
        }
      }

      @media (--viewportMedium) {
        h2,
        h4 {
          text-align: start;
        }
      }

      .downloadButtonsContainer {
        @include flexColumn;
        align-items: center;
        justify-content: center;
        width: 100%;

        @media (--viewportMedium) {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }

        a {
          text-decoration: none;
          transition: transform 0.1s ease-in-out;
          width: 135px;
          height: auto;

          @include verticalMargins(8px);

          @media (--viewportMedium) {
            @include horizontalMargins(8px);
          }

          img {
            width: 100%;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              transform: scale(1.02);
            }
          }
        }
      }
    }
  }
}

.appImageContainer {
  flex: 0 1 50%;
  @include flexRow;
  @include flexCenter;
  width: 100%;
  height: 80%;

  .imageWrapper {
    height: 100%;
    width: 100%;
    @include flexRow;
    @include flexCenter;

    img {
      width: 100%;
      max-width: 480px;

      @media (--viewportLarge) {
        transform: translateX(80px);
        max-width: 680px;
      }
    }
  }
}
