@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(359.99deg, #f9f8ff 0.01%, #ffffff 99.98%);
}

.contentWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-items: center;
}

.columnDirectionNormal {
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.columnDirectionReversed {
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-direction: row-reverse;
  }
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex: 0 1 45%;
  padding: 0px 32px;

  @media (--viewportMedium) {
    flex: 0 1 50%;
    padding: 0px 60px;
  }

  @media (--viewportLarge) {
    padding: 0px 96px;
  }
}

.illustrationContainer {
  overflow: hidden;
  flex: 0 1 55%;

  @media (--viewportMedium) {
    flex: 0 1 50%;
  }

  img {
    width: 100%;
  }
}

.illustrationOnlyContainer {
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    flex: 0 1 50%;
  }

  img {
    width: 90%;
  }
}
