@import '../../../../styles/customMediaQueries.css';

.root {
  position: relative;
  max-width: 100vw;
  height: 100%;
  overflow: hidden;
  padding-bottom: 32px;
  padding-top: 32px;
}

.controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.controlItem {
  margin: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  width: 1.5em;
  height: 1.5em;
  color: var(--fave-color-black-s400);
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (--viewportMedium) {
    font-size: 1.5rem;
  }
}

.disabled {
  filter: opacity(0.4);
  pointer-events: none;
}

.controlLinks {
  position: absolute;
  max-width: 200px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    bottom: 5%;
  }
}

.controlLinks > span {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: var(--fave-color-purple-s400);
  cursor: pointer;
  transition: all 0.2s ease-in;
  margin: 4px 6px;

  @media (--viewportMedium) {
    height: 8px;
    width: 8px;
    margin: 6px 8px;
  }
}

.sliderContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  transition: left 0.6s ease-in;
}

.sliderItem {
  min-width: 100%;
  min-height: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .controlLinks > span:hover {
    background-color: var(--fave-color-secondary-s900);
  }

  .sliderItemContent > a:hover {
    background-color: var(--fave-color-secondary-default);
    color: var(--fave-color-black);
  }
}
