@use '../LandingPage.mixins.scss' as *;
@import '../../../styles/propertySets.css';
@import '../../../styles/customMediaQueries.css';

.sectionContainer {
  @include flexColumn;
  @include flexCenter;
  width: 100%;
  height: max-content;
  
  padding-top: 64px;
  padding-bottom: 64px;
  

  @media (--viewportSmall) {
    padding-top: 24px;
  padding-bottom: 24px;
  }
  @media (--viewportMedium) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  background: linear-gradient(359.99deg, #f9f8ff 0.01%, #ffffff 99.98%);
}

.sectionContent {
  width: 100%;
  height: 100%;
  @include contentPadding;
  @include flexColumn;
  @include flexCenter;
  

  @media (--viewportLarge) {
    flex-direction: column;
  }
}

.fandomCardsContainer {
  flex: 1 1 30%;
  @include flexColumn;
  @include flexStart;
  width: 100%;
  height: 100%;
  padding-top: 32px;

  @media (--viewportMedium) {
    padding-top: 16px ;
    padding-bottom: 16px ;
  }
}

.fandomCard {
  @include flexColumn;
  @include flexCenter;
  background-color: var(--fave-color-purple-s10);
  border-radius: 12px;
  padding: 18px;
  
  width: 228px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);

  @media (--viewportMedium) {
    border-radius: 16px;
    padding: 24px;
  }

  h2 {
    color: var(--fave-color-black-s900);
    transition: color 0.2s ease-in-out;
  }

  p {
    margin: 16px 0px;
    color: var(--fave-color-black-s900);
    font-size: 20px;

    @media (--viewportMedium) {
      margin: 32px 0px;
    }
  }

  .buttonWrapper {
    border-radius: 1rem;
    width: 100%;
    max-width: 15em;
    margin: 12px 0px;

    @media (--viewportMedium) {
      margin: 16px 0px;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.01) translateY(-1%);
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.4);

      h2 {
        color: var(--fave-color-purple-s500);
      }
    }
  }
}

.fandomListingsContainer {

 
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: stretch;
  padding: 10px;

  
  overflow-x: scroll;

  @media (--viewportSmall) {
   
  }

  @media (--viewportMedium) {
    padding: 20px;
  }



  .listingCardWrapper {
    display: grid;
    place-items: center;
    width: clamp(200px, 80vw, 227px);

    margin: 9px 0px;
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }

    @media (--viewportSmall) {
      margin: 0px 9px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
    @media (--viewportMedium) {
      margin: 0px 12px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
