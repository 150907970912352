@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexCenter {
  align-items: center;
  justify-content: center;
}

@mixin flexStart {
  align-items: start;
  justify-content: start;
}

@mixin contentPadding {
  padding: 0% 4%;

  @media (--viewportMedium) {
    padding: 0% 4%;
  }
}

@mixin verticalMargins($size) {
  margin: $size 0px;
  &:first-child {
    margin-top: 0px;
  }
  &:first-child {
    margin-bottom: 0px;
  }
}

@mixin horizontalMargins($size) {
  margin: 0px $size;
  &:first-child {
    margin-left: 0px;
  }
  &:first-child {
    margin-right: 0px;
  }
}
