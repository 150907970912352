@import '../../../../styles/propertySets.css';
@import '../../../../styles/customMediaQueries.css';

.infoBoxContainer {
  display: flex;
  flex-direction: column;
  color: var(--fave-color-black-s400);
}

.question {
  width: fit-content;
  padding: 4px 16px;
  border-radius: 8px;
  background-color: #f3eeff;
  color: var(--fave-color-primary-s300);
  position: relative;
  margin-left: 16px;

  /**fave-copy-xsmall-bold*/  
    font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */;

  @media (--viewportMedium) {
    /** fave-copy-small-regular */  
   font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  }

  @media (--viewportLarge) {
    /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  }

  &::before {
    content: '';
    position: absolute;
    background-color: #f3eeff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    left: 0px;
    bottom: 0px;
    transform: translate(-50%, 50%);
  }

  &::after {
    content: '';
    position: absolute;
    background-color: #f3eeff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    left: 0px;
    bottom: 0px;
    transform: translate(-200%, 200%);
  }
}

.infoHeading {
  margin-top: 12px;
  /** marketplaceH1FontStyles*/  
 font-family: 'Greycliff';
    font-size: 40px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -0.01em;
/** end */
  margin-bottom: 0px;
  color: var(--fave-color-primary-default);


  @media (--viewportMedium) {
    font-family: Greycliff;
    font-size: 54px;
    font-weight: 800;
    line-height: 62.1px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.infoText {
  margin-top: 12px;
  line-height: 1.25;

  /**fave-copy-xsmall-regular*/  
font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 200;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */

  @media (--viewportSmall) {
    /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  }

  @media (--viewportMedium) {
    /** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  }

  @media (--viewportLarge) {
    /**fave-copy-large-regular*/  
  font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
/** end */
  }
}

.buttonContainer {
  margin-top: 1.5em;
  z-index: 1;
  width: 100%;
  max-width: 360px;

  /**fave-copy-xsmall-regular*/  
font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 200;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */

  @media (--viewportSmall) {
    /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  }

  @media (--viewportMedium) {
    /** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  }
}
